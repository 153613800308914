import { render, staticRenderFns } from "./mobileHome2.vue?vue&type=template&id=0881fae6&scoped=true&"
import script from "./mobileHome2.vue?vue&type=script&lang=js&"
export * from "./mobileHome2.vue?vue&type=script&lang=js&"
import style1 from "./mobileHome2.vue?vue&type=style&index=1&id=0881fae6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0881fae6",
  null
  
)

export default component.exports