<template>
    <div>
    </div>
</template>

<script>
    export default {
        name: "temPage",
        data(){
            return {
                map: new Map(),
            }
        },
        mounted() {
            this.setMap();
            let path = this.$router.currentRoute.fullPath;
            let gameId = path.substring(path.lastIndexOf("/")+1);
            let str = path.substring(path.indexOf("/") + 1,path.lastIndexOf("/"));
            let adminId = this.map.get(str);
            window.location.href = "https://d.7li.cn/#/d/" + adminId + "/" + gameId;
        },
        methods: {
            setMap(){
                this.map.set('tu',179);
                this.map.set('cx',248);
                this.map.set('zy',260);
                this.map.set('du',308);
                this.map.set('q',387);
                this.map.set('b',388);
                this.map.set('h',389);
            }
        }
    }
</script>

<style scoped>

</style>